<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          User detail
        </h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <template v-if="$route.query.id">
          <b-button
            v-if="!hasPaid"
            variant="outline-primary"
            @click="doUserHasPaid"
          >
            Set paid
          </b-button>
          <b-button v-else variant="outline-primary" @click="doUserHasNotPaid">
            Set not paid
          </b-button>
        </template>
        <b-button
          class="ml-2"
          :disabled="saveDisabled"
          variant="primary"
          @click="doSave"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-tabs>
      <b-tab title="General">
        <base-form
          :config="formConfig"
          v-model="user"
          :noButtons="true"
          :submitted="submitted"
        />
        <base-form
          :config="additionalInformationConfig"
          v-model="user.additional_info"
          :noButtons="true"
        />
        <base-form
          :config="additionalInformationForm1Config"
          v-model="user.additional_info_form1"
          :noButtons="true"
        />
        <base-form
          :config="additionalInformationForm2Config"
          v-model="user.additional_info_form2"
          :noButtons="true"
        />
        <base-form
          :config="additionalInformationForm3Config"
          v-model="user.additional_info_form3"
          :noButtons="true"
        />
        <base-form
          :config="additionalInformationForm4Config"
          v-model="user.additional_info_form4"
          :noButtons="true"
        />
      </b-tab>
      <b-tab v-if="$route.query.id" title="logs">
        <user-logs :user="user.email" />
      </b-tab>
      <b-tab v-if="$route.query.id" title="mails">
        <vuetable :data="mails" :fields="fields">
          <template slot="receivedat" scope="props">
            {{ props.rowData.receivedat | moment("DD/MM/YYYY, h:mm:ss a") }}
          </template>
        </vuetable>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>
<script>
import BaseForm from "../../BaseForm.vue";
import UserLogs from "./UserLogs.vue";
import Vuetable from "vuetable-2";
import { User } from "@/models/user.model";
import moment from "moment";

export default {
  components: { BaseForm, UserLogs, Vuetable },
  data() {
    return {
      isLoading: false,
      saveDisabled: true,
      saveMessage: "",
      submitted: false,
      user: "",
      fields: [
        {
          name: "messageid",
          title: "id",
        },
        {
          name: "from",
          title: "from",
        },
        {
          name: "subject",
          title: "subject",
        },
        {
          name: "status",
          title: "status",
        },
        {
          name: "receivedat",
          title: "recieved at",
        },
      ],
    };
  },
  watch: {
    user: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    hasPaid() {
      return this.user.has_paid;
    },
    users() {
      return this.$store.state.Users.users;
    },
    mails() {
      return this.$store.state.Mails.filteredMails;
    },
    formConfig() {
      return [
        {
          title: "General information",
          elements: [
            {
              field: "first_name",
              label: "first_name",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "last_name",
              label: "last_name",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "email",
              label: "email",
              type: "text",
              rules: "required",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "role",
              label: "role",
              type: "dropdown",
              values: ["admin", "moderator", "user"],
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "address",
              label: "address",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "postal_code",
              label: "postal_code",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "city",
              label: "city",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "country",
              label: "country",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "access_code",
              label: "access_code",
              type: "text",
              rules: "required",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "preferred_livestream",
              label: "preferred_livestream",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
            {
              field: "referral_token",
              label: "referral_token",
              type: "text",
              sizes: { xs: 12, sm: 6, md: 6, lg: 6 },
            },
          ],
        },
      ];
    },
    additionalInformationConfig() {
      if (!this.user.additional_info) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Additional info",
          elements,
        },
      ];
    },
    additionalInformationForm1Config() {
      if (!this.user.additional_info_form1) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info_form1)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Form 1",
          elements,
        },
      ];
    },
    additionalInformationForm2Config() {
      if (!this.user.additional_info_form2) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info_form2)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Form 2",
          elements,
        },
      ];
    },
    additionalInformationForm3Config() {
      if (!this.user.additional_info_form3) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info_form3)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Form 3",
          elements,
        },
      ];
    },
    additionalInformationForm4Config() {
      if (!this.user.additional_info_form4) {
        return [];
      }
      const elements = [];
      for (const key of Object.keys(this.user.additional_info_form4)) {
        elements.push({
          field: key,
          label: key,
          type: "text",
          sizes: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 6,
          },
        });
      }
      return [
        {
          title: "Form 4",
          elements,
        },
      ];
    },
  },
  methods: {
    async doUserHasPaid() {
      this.isLoading = true;
      await this.$store.dispatch("Users/setHasPaid", this.user.id);
      this.user = this.$store.getters["Users/current"];
      this.isLoading = false;
    },
    async doUserHasNotPaid() {
      this.isLoading = true;
      await this.$store.dispatch("Users/setHasNotPaid", this.user.id);
      this.user = this.$store.getters["Users/current"];
      this.isLoading = false;
    },
    onPaginationData(data) {
      console.log(data);
    },
    async doFetch(id) {
      this.isLoading = true;
      if (id) {
        await this.$store.dispatch("Users/fetchById", id);
        this.user = this.$store.getters["Users/current"];
      } else {
        this.user = new User(
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        );
      }

      this.isLoading = false;
    },
    async fetchMails() {
      this.isLoading = true;
      await this.$store.dispatch("Mails/fetchByRecipient", this.user?.email);
      this.isLoading = false;
    },
    async doSave() {
      let action = this.$route.query.id ? "Users/update" : "Users/create";

      this.$validator.validateAll().then(async (isValid) => {
        this.submitted = true;
        if (!isValid) {
          return;
        }
        this.isLoading = true;

        await this.$store
          .dispatch(action, this.user)
          .then((response) => {
            console.log(response);
            this.$root.$bvToast.toast("User saved!", { variant: "success" });
            this.saveDisabled = true;
            this.saveMessage = "";
            // this.$route.query.id = response.id
            this.doFetch(this.$route.query.id);
            this.submitted = false;
          })
          .catch((error) => {
            console.log(error);
            this.$root.$bvToast.toast(
              `There was an error saving the user. ${error.message}`,
              { variant: "danger" }
            );
          });

        this.isLoading = false;
      });
    },
  },
  async created() {
    await this.doFetch(this.$route.query.id);
    this.submitted = false;
    this.saveDisabled = true;
    this.saveMessage = "";
  },
};
</script>
<style lang="scss"></style>
